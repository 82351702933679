import React, { Component } from "react";

class Skills extends Component {
  componentDidMount() {
    // Dodaj event listener za skrol
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    // Ukloni event listener kada komponenta više nije vidljiva
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const titleElement = document.querySelector("#skills .section-title");

    if (titleElement) {
      const rect = titleElement.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.top <= windowHeight - 100 && rect.bottom >= 0) {
        titleElement.classList.add("visible");
      } else {
        titleElement.classList.remove("visible");
      }
    }
  };

  render() {
    if (this.props.sharedSkills && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.skills;
      var skills = this.props.sharedSkills.icons.map(function (skills, i) {
        return (
          <li className="list-inline-item mx-3" key={i}>
            <span>
              <div className="text-center skills-tile">
                <i className={`${skills.class} skill-icon`} style={{ fontSize: "220%" }}>
                  <p
                    className="text-center"
                    style={{ fontSize: "30%", marginTop: "4px" }}
                  >
                    {skills.name}
                  </p>
                </i>
              </div>
            </span>
          </li>
        );
      });
    }

    return (
      <section id="skills">
        <div className="col-md-12">
          <div className="col-md-12">
            <h1 className="section-title slide-in-left">
              <span className="text-white">{sectionName}</span>
            </h1>
          </div>
          <div className="col-md-12 text-center">
            <ul className="list-inline mx-auto skill-icon">{skills}</ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
