import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";

class Projects extends Component {
  componentDidMount() {
    this.loadParticlesTwo();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("themeChange", this.loadParticlesTwo);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("themeChange", this.loadParticlesTwo);

    if (window.pJSDom && window.pJSDom.length > 1) {
      window.pJSDom[1]?.pJS.fn.vendors.destroypJS();
      window.pJSDom.splice(1, 1); // Ukloni samo efekt za Projects sekciju
    }
  }

  loadParticlesTwo = () => {
    const particlesColorTwo = getComputedStyle(document.body).getPropertyValue("--particles-two-color").trim();

    if (window.pJSDom && window.pJSDom.length > 1) {
      window.pJSDom[1].pJS.fn.vendors.destroypJS();
      window.pJSDom.splice(1, 1); // Remove only the second particles effect
    }

    window.particlesJS("particles-projects", {
      particles: {
        number: { value: 150, density: { enable: true, value_area: 800 } },
        color: { value: particlesColorTwo },
        shape: { type: "circle" },
        opacity: { value: 0.5, random: true },
        size: { value: 3, random: true },
        line_linked: { enable: false },
        move: { enable: true, speed: 0.6, direction: "none", out_mode: "out" }
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: { enable: false, mode: "repulse" },
          onclick: { enable: true, mode: "push" }
        },
        modes: { push: { particles_nb: 4 } }
      },
      retina_detect: true
    });
  };

  handleScroll = () => {
    const titleElement = document.querySelector("#portfolio .section-title");

    if (titleElement) {
      const rect = titleElement.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.top <= windowHeight - 100 && rect.bottom >= 0) {
        titleElement.classList.add("visible");
      } else {
        titleElement.classList.remove("visible");
      }
    }
  };

  render() {
    if (this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.projects;
    }

    return (
      <section id="portfolio" style={{ position: "relative", overflow: "hidden" }}>
        <div id="particles-projects" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 0 }}></div>
        
        <div style={{
          position: "absolute",
          top: "10%",
          left: "25%",
          width: "50%",
          height: "80%",
          backgroundColor: "transparent",
          zIndex: 1
        }}></div>
        
        <div className="col-md-12" style={{ position: "relative", zIndex: 2 }}>
          <h1 className="section-title slide-in-left" style={{ color: "black" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="col-md-12 text-center">
            <a href="https://github.com/domic98" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-github beat-icon" style={{ fontSize: "180px", color: "black" }}></i>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default Projects;
