import React, { Component } from "react";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typedText: "",
    };
    this.fullText = "";
  }

  componentDidMount() {
    // Inicijalno postavi sadržaj za animirani tekst
    this.setLanguageContent();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    // Ažuriraj sadržaj ako se promijeni jezik ili informacije
    if (prevProps.resumeBasicInfo !== this.props.resumeBasicInfo || prevProps.selectedLanguage !== this.props.selectedLanguage) {
      this.setLanguageContent();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  setLanguageContent = () => {
    const { resumeBasicInfo } = this.props;

    if (resumeBasicInfo) {
      this.fullText = resumeBasicInfo.description; // Ovdje postavljamo opis koji će biti prikazan
      this.setState({ typedText: "" }, () => this.startTyping());
    }
  };

  handleScroll = () => {
    const titleElement = document.querySelector("#about .section-title");
    const imageElement = document.querySelector("#about .polaroid");

    if (titleElement) {
      const rect = titleElement.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.top <= windowHeight - 100 && rect.bottom >= 0) {
        titleElement.classList.add("visible");
      } else {
        titleElement.classList.remove("visible");
      }
    }

    if (imageElement) {
      const rect = imageElement.getBoundingClientRect();
      if (rect.top <= window.innerHeight - 100 && rect.bottom >= 0) {
        imageElement.classList.add("visible");
      } else {
        imageElement.classList.remove("visible");
      }
    }
  };

  startTyping = () => {
    this.typeCharacter(0);
  };

  typeCharacter = (index) => {
    if (index < this.fullText.length) {
      this.setState((prevState) => ({
        typedText: prevState.typedText + this.fullText[index],
      }));
      setTimeout(() => this.typeCharacter(index + 1), 20);
    }
  };

  render() {
    const { sharedBasicInfo, resumeBasicInfo } = this.props;
    const profilepic = sharedBasicInfo ? "/images/" + sharedBasicInfo.image : "";
    const sectionName = resumeBasicInfo ? resumeBasicInfo.section_name.about : "";
    const hello = resumeBasicInfo ? resumeBasicInfo.description_header : "";

    return (
      <section id="about">
        <div className="col-md-12">
          <h1 className="section-title slide-in-left" style={{ color: "black" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            <div className="col-md-4 mb-5 center">
              <div className="polaroid pop-in-left">
                <span style={{ cursor: "auto" }}>
                  <img height="250px" src={profilepic} alt="Avatar placeholder" />
                </span>
              </div>
            </div>

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card new-card">
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                      borderRadius: "20px",
                      zIndex: 999,
                    }}
                  >
                    <br />
                    <span className="wave" dangerouslySetInnerHTML={{ __html: hello }} />
                    <br />
                    <br />
                    <span className="typewriter-text" dangerouslySetInnerHTML={{ __html: this.state.typedText }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
